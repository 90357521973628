import { sanitize } from 'dompurify';

export const sanitizeHtml = (
  html: TemplateStringsArray | string | number,
  allowedTags = false,
) => {
  const config = {
    ALLOWED_TAGS: allowedTags ? undefined : [],
  };

  const parsedHtml =
    (html as TemplateStringsArray).raw?.map((str) => str).join('') ||
    String(html);
  return sanitize(parsedHtml, { FORBID_ATTR: ['href'], ...config });
};
